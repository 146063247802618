<template>
	<Page
		v-if="website"
		:page-data="pageData"
		:current-page-id="currentPageId"
	/>
</template>

<script>
import {
	defineComponent,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import Page from '@/components/Page.vue';

import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';

export default defineComponent({
	name: 'PageWrapper',
	components: {
		Page,
	},
	setup() {
		const { state } = useStore();
		const route = useRoute();

		const website = computed(() => state.website);
		const defaultLocale = computed(() => state.website?.meta?.defaultLocale ?? SYSTEM_LOCALE);

		// `currentLocale` is parsed out according to current route
		const currentLocale = computed(() => {
			const { locale } = getPathParams({
				path: route.path,
				defaultLocale: defaultLocale.value,
				languages: state.website?.languages,
			});

			return locale;
		});

		const currentPageId = computed(() => getPageIdFromPath({
			path: route.path,
			siteData: state.website,
		}));

		const languageData = computed(() => state.website?.languages[currentLocale.value] ?? {});

		const pageData = computed(() => ({
			currentLocale: currentLocale.value,
			pages: languageData.value.pages ?? {},
			blocks: languageData.value.blocks ?? {},
			elements: languageData.value.elements ?? {},
			homePageId: languageData.value.homePageId,
			isNavHidden: languageData.value.isNavHidden,
			cookieBannerAcceptText: languageData.value.cookieBannerAcceptText,
			cookieBannerDisclaimer: languageData.value.cookieBannerDisclaimer,
			cookieBannerDeclineText: languageData.value.cookieBannerDeclineText,
			meta: state.website?.meta ?? {},
			forms: state.website?.forms ?? {},
			styles: state.website?.styles ?? {},
			siteId: state.website?.siteId,
			ecommerceShoppingCart: state.website?.ecommerceShoppingCart ?? {},
			blogCategories: state.website?.blogCategories,
		}));

		return {
			website,
			pageData,
			defaultLocale,
			currentPageId,
			currentLocale,
		};
	},
});
</script>
